import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["preview"];

  input_change(event) {
    this.previewTarget.classList.remove("hidden");
    this.previewTarget.src = URL.createObjectURL(event.target.files[0]);
  }
}
