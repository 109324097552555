<template>
  <div class="">
    <h3 class="font-semibold mt-4">Choose a plan</h3>
    <fieldset class="mt-2">
      <ul class="space-y-4" role="radiogroup">
        <li
          v-for="plan in plans"
          :key="plan.id"
          role="radio"
          class="group relative bg-white dark:bg-gray-900 rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
          @click="selectPlan(plan)"
        >
          <div
            class="rounded-lg border border-gray-300 bg-white dark:bg-gray-900 px-6 py-4 hover:border-gray-400 dark:border-gray-700 dark:hover:border-gray-800 sm:flex sm:justify-between"
          >
            <div class="flex items-center w-4/5">
              <div class="text-sm">
                <p class="font-medium text-gray-900 dark:text-gray-100">
                  {{ plan.name }}
                </p>
                <div class="text-gray-700 dark:text-gray-500">
                  <p class="sm:inline">{{ plan.description }}</p>
                </div>
              </div>
            </div>
            <div
              class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right flex-shrink-0 w-1/5"
            >
              <div class="font-medium text-gray-900 dark:text-gray-200">
                {{ plan.price }}
              </div>
              <div class="ml-1 text-gray-700 dark:text-gray-500 sm:ml-0">
                {{ plan.period }}
              </div>
            </div>
          </div>
          <div
            class="absolute inset-0 rounded-lg border-2 pointer-events-none"
            :class="{
              'border-indigo-500': plan.selected,
              'border-transparent': !plan.selected,
            }"
          ></div>
        </li>
      </ul>
    </fieldset>

    <div class="text-center mt-4" v-if="false">
      <button
        type="button"
        class="button-size-md button-style-primary"
        @click.prevent="loadCheckout()"
      >
        Go to checkout
      </button>
    </div>

    <div class="" v-if="true">
      <h3 class="font-semibold mt-6">Payment details</h3>
      <form
        id="payment-form"
        action="/subscription"
        accept-charset="UTF-8"
        method="post"
        class="mt-2"
      >
        <input type="hidden" name="authenticity_token" :value="this.token" />
        <div class="stripe-input">
          <label for="card-element">Card</label>
          <div id="card-element"></div>
        </div>
        <div id="card-errors" role="alert"></div>
        <div class="mt-2">
          <label for="Name on card" class="input-label text-sm"
            >Name on card</label
          >
          <input
            id="name_on_card"
            placeholder="Full name"
            type="text"
            name="name_on_card"
            class="input-field"
            required
          />
        </div>
        <div class="mt-8">
          <label for="line1" class="input-label text-sm">Address line 1</label>
          <input
            type="text"
            name="line1"
            id="line1"
            class="input-field"
            required
          />
        </div>
        <div class="mt-2">
          <label for="line2" class="input-label text-sm"
            >Address line 2 (optional)</label
          >
          <input type="text" name="line2" id="line2" class="input-field" />
        </div>
        <div class="mt-2">
          <slot></slot>
        </div>
        <div class="mt-2 flex">
          <div class="w-1/2 pr-1">
            <label for="postal_code" class="input-label text-sm"
              >Postal code</label
            >
            <input
              type="text"
              name="postal_code"
              id="postal_code"
              class="input-field"
              required
            />
          </div>
          <div class="w-1/2 pl-1">
            <label for="city" class="input-label text-sm">City</label>
            <input
              type="text"
              name="city"
              id="city"
              class="input-field"
              required
            />
          </div>
        </div>
        <input :value="selectedPlanId" type="hidden" name="plan" id="plan" />
        <input value="stripe" type="hidden" name="processor" id="processor" />
        <div class="mt-4 text-right mb-4">
          <input
            type="submit"
            name="commit"
            value="Subscribe"
            data-disable-with="Subscribe"
            class="button-size-md button-style-primary"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["token"],
  data() {
    return {
      stripeInstance: null,
      plans: [
        {
          id: "price_1IampkAfkZzKvMIutL60NeAH",
          name: "Quarterly",
          description:
            "Let's start it rolling during these three months. Learn how to ship your online products.",
          price: "$60",
          period: "every three months",
          selected: true,
        },
        {
          id: "price_1IampkAfkZzKvMIuVVCBNmg1",
          name: "Yearly",
          description:
            "Go for this commitment if you are serious about building side projects that make money.",
          price: "$180",
          period: "every year",
          selected: false,
        },
      ],
    };
  },

  mounted() {
    let cardElement = document.querySelector("#card-element");

    if (cardElement !== null) {
      this.setupStripe();
    }

    // Handle users with existing card who would like to use a new one
    let newCard = document.querySelector("#use-new-card");
    if (newCard !== null) {
      newCard.addEventListener("click", (event) => {
        event.preventDefault();
        document.querySelector("#payment-form").classList.remove("d-none");
        document.querySelector("#existing-card").classList.add("d-none");
      });
    }
  },

  computed: {
    selectedPlanId() {
      return this.plans.filter((plan) => {
        if (plan.selected) {
          return plan;
        }
      })[0].id;
    },
  },

  methods: {
    selectPlan(selectedPlan) {
      this.plans.forEach((plan) => {
        if (plan.id === selectedPlan.id) {
          plan.selected = true;
        } else {
          plan.selected = false;
        }
      });
    },

    loadCheckout() {
      const stripe_key = document
        .querySelector("meta[name='stripe-key']")
        .getAttribute("content");
      const stripe = Stripe(stripe_key);

      let selectedPlan = this.plans.filter((plan) => {
        if (plan.selected) {
          return plan;
        }
      })[0].id;

      let fd = new FormData();
      fd.append("price_id", selectedPlan);

      Rails.ajax({
        url: "/subscription/checkout",
        type: "POST",
        data: fd,
        success: (data) => {
          stripe.redirectToCheckout({
            sessionId: data.id,
          });
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },

    setupStripe() {
      const stripe_key = document
        .querySelector("meta[name='stripe-key']")
        .getAttribute("content");
      const stripe = Stripe(stripe_key);
      this.stripeInstance = stripe;

      const elements = stripe.elements();
      const card = elements.create("card");
      card.mount("#card-element");

      var displayError = document.getElementById("card-errors");

      card.addEventListener("change", (event) => {
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });

      const form = document.querySelector("#payment-form");
      let paymentIntentId = form.dataset.paymentIntent;
      let setupIntentId = form.dataset.setupIntent;

      if (paymentIntentId) {
        if (form.dataset.status == "requires_action") {
          stripe
            .confirmCardPayment(paymentIntentId, {
              setup_future_usage: "off_session",
            })
            .then((result) => {
              if (result.error) {
                displayError.textContent = result.error.message;
                form.querySelector("#card-details").classList.remove("d-none");
              } else {
                form.submit();
              }
            });
        }
      }

      form.addEventListener("submit", (event) => {
        event.preventDefault();

        let name = form.querySelector("#name_on_card").value;
        let country = form.querySelector("#country").value;
        let line1 = form.querySelector("#line1").value;
        let line2 = form.querySelector("#line2").value;
        let city = form.querySelector("#city").value;
        let postal_code = form.querySelector("#postal_code").value;

        console.log(name);
        console.log(country);
        console.log(line1);
        console.log(line2);
        console.log(city);
        console.log(postal_code);

        let data = {
          payment_method_data: {
            card: card,
            billing_details: {
              name: name,
              address: {
                country: country,
                line1: line1,
                line2: line2,
                city: city,
                postal_code: postal_code,
              },
            },
          },
        };

        console.log(data);

        // Complete a payment intent
        if (paymentIntentId) {
          stripe
            .confirmCardPayment(paymentIntentId, {
              payment_method: data.payment_method_data,
              setup_future_usage: "off_session",
              save_payment_method: true,
            })
            .then((result) => {
              if (result.error) {
                displayError.textContent = result.error.message;
                form.querySelector("#card-details").classList.remove("d-none");
              } else {
                form.submit();
              }
            });

          // Updating a card or subscribing with a trial (using a SetupIntent)
        } else if (setupIntentId) {
          stripe
            .confirmCardSetup(setupIntentId, {
              payment_method: data.payment_method_data,
            })
            .then((result) => {
              if (result.error) {
                displayError.textContent = result.error.message;
              } else {
                this.addHiddenField(
                  form,
                  "card_token",
                  result.setupIntent.payment_method
                );
                form.submit();
              }
            });
        } else {
          // Subscribing with no trial
          data.payment_method_data.type = "card";
          stripe
            .createPaymentMethod(data.payment_method_data)
            .then((result) => {
              if (result.error) {
                displayError.textContent = result.error.message;
              } else {
                this.addHiddenField(
                  form,
                  "card_token",
                  result.paymentMethod.id
                );
                form.submit();
              }
            });
        }
      });
    },

    addHiddenField(form, name, value) {
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", name);
      input.setAttribute("value", value);
      form.appendChild(input);
    },
  },
};
</script>

<style></style>
