import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = [];

  connect() {
    StimulusReflex.register(this);
  }

  remove_guest_from_event(event) {
    const el = event.currentTarget;
    const ok = confirm(el.getAttribute("data-message"));

    if (!ok) {
      return false;
    } else {
      const event_id = el.getAttribute("data-id");
      const guest_id = el.getAttribute("data-guest-id");
      this.stimulate(
        "EventsReflex#remove_guest_from_event",
        event_id,
        guest_id
      );
    }
  }
}
