import Rails from "@rails/ujs";
window.Rails = Rails;
Rails.start();

require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("trix");
require("@rails/actiontext");

import "controllers";
require("css/application.scss");

import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm";

import VCalendar from "v-calendar";
import CalendarPicker from "../components/calendar";
import SubForm from "../components/subscription_form";

Vue.use(TurbolinksAdapter);
Vue.use(VCalendar, {});

const initVue = () => {
  const app = new Vue({
    el: "#vue-container",
    data: () => {
      return {};
    },
    components: {
      "calendar-picker": CalendarPicker,
      "subscription-form": SubForm,
    },
  });
};

document.addEventListener("stimulus-reflex:success", initVue);
document.addEventListener("turbolinks:load", initVue);
