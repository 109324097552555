<template>
  <div class="">
    <input
      type="hidden"
      :name="this.inputName"
      :id="this.inputId"
      :value="dateValue"
    />

    <v-date-picker
      :mode="this.mode"
      v-model="dateValue"
      :is-dark="darkMode"
      color="purple"
      :timezone="convertTZ(this.timezone)"
      :minute-increment="15"
      is24hr
    >
      <template v-slot="{ inputValue, togglePopover }">
        <button type="button" @click="togglePopover" class="input-field">
          {{ inputValue }}
        </button>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import RailsTimeZone from "../utilities/timezone_converter";

export default {
  props: ["mode", "inputName", "inputId", "inputValue", "timezone"],
  data() {
    return {
      darkMode: false,
      dateValue:
        this.inputValue.length > 0 ? new Date(this.inputValue) : new Date(),
    };
  },
  created() {
    // check for dark mode
    if (document.firstElementChild.classList.contains("dark")) {
      this.darkMode = true;
    }
  },
  components: {},
  methods: {
    convertTZ(tz) {
      return RailsTimeZone.from(tz);
    },
  },
};
</script>
