import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    let trix_editor = this.element.querySelector("trix-editor");
    trix_editor.classList.add("prose");
    trix_editor.classList.add("dark:prose-light");

    if (document.firstElementChild.classList.contains("dark")) {
      trix_editor.classList.add("border-gray-900");
      trix_editor.classList.add("bg-gray-900");
    }
  }
}
