import { Controller } from "stimulus";
import { EmojiButton } from "@joeattardi/emoji-button";

export default class extends Controller {
  static targets = ["preview", "logoInput", "emojiInput", "emojiButton"];

  initialize() {
    this.picker = new EmojiButton();
    this.picker.on("emoji", (selection) => {
      this.emojiInputTarget.value = selection.emoji;
      this.emojiButtonTarget.innerHTML = selection.emoji;
    });
  }

  showPicker(event) {
    event.preventDefault();
    this.picker.togglePicker(event.target);
  }

  input_change(event) {
    this.previewTarget.classList.remove("hidden");
    this.previewTarget.src = URL.createObjectURL(event.target.files[0]);
  }
}
