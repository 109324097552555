import { Controller } from "stimulus";
const getCaretCoordinates = require("textarea-caret");

const upKey = 38;
const downKey = 40;
const enterKey = 13;
const navigationKeys = [upKey, downKey, enterKey];

export default class extends Controller {
  static targets = [
    "add_item",
    "template",
    "submit_button",
    "field",
    "suggestionTemplate",
    "suggestion",
  ];

  connect() {
    if (this.fields_have_content()) {
      this.submit_buttonTarget.disabled = false;
    } else {
      this.submit_buttonTarget.disabled = true;
    }

    this.currentResultIndex = 0;
    this.selectCurrentResult();
  }

  input_change(event) {
    // Check for empty fields and disable/enable submit btn accordingly
    if (this.fields_have_content()) {
      this.submit_buttonTarget.disabled = false;
    } else {
      this.submit_buttonTarget.disabled = true;
    }

    // Check for "#tag" to show project suggestions / autocomplete
    const suggestionsGroupElement = event.target
      .closest(".nested-fields")
      .querySelector(".suggestions-group");

    if (event.target.value.match(/#[a-zA-Z0-9-]+$/)) {
      const url = new URL(this.data.get("url"));
      const query = event.target.value.match(/#[a-zA-Z0-9-]+$/)[0].substring(1);
      suggestionsGroupElement.innerHTML = "";

      url.searchParams.append("query", query);

      var caret = getCaretCoordinates(event.target, event.target.selectionEnd);

      fetch(url)
        .then((response) => response.json())
        .then((results) => {
          if (results.length > 0) {
            results.forEach((item) => {
              let resultTemplate = this.suggestionTemplateTarget;

              let content = resultTemplate.innerHTML
                .replace(/SLUG/g, "#" + item.slug)
                .replace(/PROJECT_NAME/g, item.name);

              suggestionsGroupElement.insertAdjacentHTML("beforeend", content);
            });

            suggestionsGroupElement.style.top = caret.top + 10 + "px";
            suggestionsGroupElement.style.left = caret.left - 26 + "px";
            suggestionsGroupElement.classList.remove("hidden");

            this.currentResultIndex = 0;
            this.selectCurrentResult();
          } else {
            suggestionsGroupElement.classList.add("hidden");
          }
        })
        .catch(() => {});
    } else {
      suggestionsGroupElement.classList.add("hidden");
      suggestionsGroupElement.innerHTML = "";
    }
  }

  select_tag(event) {
    event.preventDefault();

    // replace last #tag with complete slug
    const inputElement = event.target
      .closest(".nested-fields")
      .querySelector(".task-input");

    const regex = /#[a-z0-9-]+$/;
    inputElement.value = inputElement.value.replace(regex, event.target.hash);

    this.reset_suggestions(event);
  }

  prevent(event) {
    event.preventDefault();
  }

  reset_suggestions(event) {
    const suggestionsGroupElement = event.target
      .closest(".nested-fields")
      .querySelector(".suggestions-group");
    suggestionsGroupElement.innerHTML = "";
    suggestionsGroupElement.classList.add("hidden");
  }

  navigate_results(event) {
    if (!navigationKeys.includes(event.keyCode)) {
      return;
    }

    event.preventDefault();

    switch (event.keyCode) {
      case downKey:
        this.selectNextResult();
        break;
      case upKey:
        this.selectPreviousResult();
        break;
      case enterKey:
        this.goToSelectedResult();
        break;
    }
  }

  selectCurrentResult() {
    this.suggestionTargets.forEach((element, index) => {
      element.classList.toggle(
        "suggestion--selected",
        index == this.currentResultIndex
      );
    });
  }

  selectNextResult() {
    if (this.currentResultIndex < this.suggestionTargets.length - 1) {
      this.currentResultIndex++;
      this.selectCurrentResult();
    }
  }

  selectPreviousResult() {
    if (this.currentResultIndex > 0) {
      this.currentResultIndex--;
      this.selectCurrentResult();
    }
  }

  goToSelectedResult() {
    let el = this.suggestionTargets[this.currentResultIndex];

    if (el) {
      const inputElement = el
        .closest(".nested-fields")
        .querySelector(".task-input");

      const regex = /#[a-zA-Z0-9-]+$/;
      inputElement.value = inputElement.value.replace(regex, el.hash);

      this.reset_suggestions(event);
    } else {
      this.add_association();
    }
  }

  fields_have_content() {
    // Returns true if at least one of the fields has content
    let result = false;

    this.fieldTargets.forEach((field) => {
      if (field.value.length > 0) {
        result = true;
      }
    });

    return result;
  }

  add_association(event) {
    if (event) {
      event.preventDefault();
    }

    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.add_itemTarget.insertAdjacentHTML("beforebegin", content);
    this.fieldTargets[this.fieldTargets.length - 1].focus();
  }

  remove_association(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }
}
