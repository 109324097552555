import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    let content = this.element;
    content.classList.add("prose");
    content.classList.add("dark:prose-light");
  }
}
