import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  toggle(event) {
    event.preventDefault();
    document.firstElementChild.classList.toggle("dark");
    this.stimulate("ColorsReflex#toggle_mode");
  }
}
